import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import {HTMLContent} from '../components/Content'
import TeamPageTemplate from '../components/TeamPageTemplate'

const TeamPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <div>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <TeamPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        offerings={post.frontmatter.offerings}
        bg_hero={post.frontmatter.bg_hero}
        lang= {post.frontmatter.lang}
      />
    </div>
  )
}

TeamPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeamPage

export const aboutPageQuery = graphql`
  query TeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta_title
        lang
        meta_description
        bg_hero
        slug
        offerings {
          blurbs {
            image
            title
            text
          }
        }
      }
    }
  }
`
