import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import Offerings from '../Offerings'
import { FormattedMessage } from 'react-intl'
import SoloImageHero from '../SoloImage/SoloImageHero'

const TeamPageTemplate = ({title, lang, content, contentComponent, bg_hero, offerings }) => {
  const PageContent = contentComponent || Content

  return (

    <div>

    <section id="hero_img">
    <SoloImageHero imgName={bg_hero} title={title} />
    </section>
    {/*  <section className='hero is-primary is-bold is-medium'>
        <div className={'kenburns-bottom hero-body ' + bg_hero}>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title focus-in-contract-bck'>
                    {title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <PageContent className='content' content={content} />
                <Offerings gridItems={offerings.blurbs} />
                {/*  <FormattedMessage id="aboutCopy" /> */}
                <hr/>
                <p>
                {lang == 'en' ?
                'A dedicated team of process engineers, computer scientists, physicists, mathematicians and smart people are constantly cooperating to bridge the span from the industrial real world via the process engineers view into the model representation and finally into the computer simulation. And back!'
                :
                'Ein hochqualifiziertes Team aus Prozessingenieuren, Computerwissenschaftlern, Physikern, Mathematikern und cleveren Technikern arbeiten ständig daran, die industrielle Realität über die Prozessmodelle mit der Computersimulation zu verbinden. Und dann anschließend deren Ergebnisse wieder zurück zu liefern an die Realität!'
                }
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

TeamPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  bg_hero: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

export default TeamPageTemplate
